import "./bootstrap";
import "./common";
import "prismjs";
import "prismjs/themes/prism.css";
import "prismjs/components/prism-json";
import {resizeFont} from "./font.js";

document.addEventListener("DOMContentLoaded", () => {
    Prism.highlightAll();
});

resizeFont();
